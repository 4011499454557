export const anesthesiaCodes = [
  { premium: "time", code: "E400C", description: "Evenings 17-24h", units: "+50%" },
  { premium: "time", code: "E400C", description: "Weekends 07-24h", units: "+50%" },
  { premium: "time", code: "E401C", description: "Nights 24-07h", units: "+75%" },
  { premium: "ASA", code: "E022C", description: "ASA III", units: "+ 10U"},
  { premium: "ASA", code: "E017C", description: "ASA IV", units: "+ 10U"},
  { premium: "ASA", code: "E016C", description: "ASA V", units: "+ 20U"},
  { premium: "emergency", code: "E020C", description: "ASA E", units: "+ 4U"},
  { premium: "call-in", code: "E998C", description: "Call-in evenings", units:"$60.00"},
  { premium: "call-in", code: "E998C", description: "Call-in nights", units:"$100.00"},
  { premium: "bariatric", code: "E010C", description: "BMI > 40", units: "+ 2U"},
  { premium: "position", code: "E011C", description:"Prone position",units:"+ 4U"},
  { premium: "position", code: "E024C", description:"Sitting >60 degrees",units:"+ 4U"},
  { premium: "age", code: "E014C", description:"Newborn (0-28 days)",units:"+ 5U"},
  { premium: "age", code: "E009C", description:"Infant (29d - 1 year)",units:"+ 4U"},
  { premium: "age", code: "E019C", description:"1 - 8 years",units:"+ 2U"},
  { premium: "age", code: "E007C", description:"70-79 years",units:"+ 1U"},
  { premium: "age", code: "E018C", description:"80+ years",units:"+ 3U"},
];
